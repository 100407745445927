import React from "react";
import { isMobileOnly } from "react-device-detect";
import formatCurrency from "./utils/formatCurrency";
import Chart from "./Chart";

export default function VolumeShare({ pairs, totalExchangeVolume }) {
  const pairsToShow = isMobileOnly ? 4 : 10;
  const pairsWithVolume = Array.from(Object.values(pairs || {}))
    .sort((a, b) => b.volume - a.volume)
    .slice(0, pairsToShow);

  return (
    <div className="volumeShare" style={{ marginTop: "10vh" }}>
      <h1>Top Assets by Volume</h1>
      <Chart
        config={{
          type: "pie",
          data: {
            labels: pairsWithVolume.map((p) => p.pairName),
            datasets: [
              {
                label: "Volume",
                data: pairsWithVolume.map((p) => p.volume.toFixed(2)),
                backgroundColor: "#003f5c #284a78 #58508d #8b5196 #bc5090 #e4537d #ff6361 #ff813d #ffa600"
                  .split(" ")
                  .reverse(),
              },
            ],
          },
          options: {
            legend: {
              position: "left",
              labels: {
                fontColor: "#999",
                fontSize: 16,
                padding: 20,
              },
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const pairName = data.labels[tooltipItem.index];
                  const volume = Number(
                    data.datasets[0].data[tooltipItem.index]
                  ).toFixed(2);
                  const percentage = (
                    (Number(volume) / Number(totalExchangeVolume)) *
                    100
                  ).toFixed(2);
                  var label = `${pairName}: ${formatCurrency(
                    volume
                  )} (${percentage}%)`;
                  return label;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}
