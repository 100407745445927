import React from "react";
import formatCurrency from "./utils/formatCurrency";
import formatNumber from "./utils/formatNumber";
import colors from "./styles/colors";
import Chart from "./Chart";

export default function Depth({ depth }) {
  return (
    <div className="depthChart" style={{ marginTop: "10vh" }}>
      <h1>Depth Chart</h1>
      <Chart
        height="70vh"
        config={{
          type: "line",
          data: {
            labels: [...depth.bid.map((b) => b?.val), ...depth.ask.map((a) => a?.val)],
            datasets: [
              {
                // xAxisID: "x-bid",
                label: "Bid",
                backgroundColor: colors.greenT,
                borderColor: colors.green,
                pointBackgroundColor: "#fff",
                pointBorderColor: colors.green,
                pointBorderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 0,
                data: depth.bid.map((b) => ({
                  x: b?.val,
                  y: b.totalVol,
                  marginalVol: b.vol,
                })),
                steppedLine: "true",
                max: depth.bid[depth.bid.length - 1]?.val,
              },
              {
                // xAxisID: "x-ask",
                label: "Ask",
                backgroundColor: colors.redT,
                borderColor: colors.red,
                pointBackgroundColor: "#fff",
                pointBorderColor: colors.red,
                pointBorderWidth: 2,
                pointRadius: 1,
                pointHoverRadius: 2,
                data: depth.ask.map((a) => ({
                  x: a?.val,
                  y: a.totalVol,
                  marginalVol: a.vol,
                })),
                steppedLine: "true",
                min: depth.ask[0]?.val,
              },
            ],
          },

          options: {
            maintainAspectRatio: false,
            legend: {
              labels: {
                fontColor: "#999",
                fontSize: 16,
              },
            },
            scales: {
              yAxes: [
                {
                  //stacked: true,
                  ticks: {
                    fontSize: 18,
                    fontColor: "#999",
                  },
                },
              ],
              xAxes: [
                {
                  display: true,
                  ticks: {
                    //min: xcmMinPrice - xcmMinPrice * 0.01,
                    fontSize: 18,
                    fontColor: "#999",
                  },
                },
              ],
            },

            tooltips: {
              mode: "x",
              intersect: false,
              titleFontSize: 14,
              bodyFontSize: 16,
              callbacks: {
                label: function (tooltipItem, data) {
                  const item =
                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                  return `Volume: ${formatNumber(
                    item.marginalVol
                  )} \n Cumulative:${formatNumber(item.y)}`;
                },
                title: function (tooltipItem, data) {
                  const item =
                    data.datasets[tooltipItem[0].datasetIndex].data[
                      tooltipItem[0].index
                    ];

                  return `${formatCurrency(item.x)}`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}
