import React, { useState } from "react";
import colors from "./styles/colors";
import Chart from "./Chart";

export default function CryptoSentiment({ pairs }) {
  const [selectedAsset, setSelectedAsset] = useState("btc");
  return (
    <div
      id="crypto-sentiment"
      className="sentimentChart"
      style={{ marginTop: "10vh", marginBottom: "10vh" }}
    >
      <h1>Crypto Twitter Sentiment Indicator</h1>
      <h4>
        (
        <a href="https://coinmetro.exchange" className="App-link">
          Sign up
        </a>{" "}
        to access sentiment data for more assets)
      </h4>
      <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <Chart
          key={selectedAsset}
          config={{
            type: "gauge",
            data: {
              datasets: [
                {
                  value:
                    pairs[selectedAsset.toUpperCase() + "EUR"].sentimentData
                      .sentiment,
                  data: [50, 100],
                  backgroundColor: [`${colors.redT}`, `${colors.greenT}`],
                },
              ],
            },
            options: {
              needle: {
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
                color: "rgba(0, 0, 0, 1)",
              },
              valueLabel: {
                display: true,
                formatter: (value) => {
                  return `${value.toFixed(2)}% (${getSentimentLabel(value)})`;
                },
                color: "rgba(255, 255, 255, 1)",
                fontSize: 20,
                backgroundColor: "rgba(0, 0, 0, 1)",
                borderRadius: 5,
                padding: {
                  top: 10,
                  bottom: 10,
                },
              },
            },
          }}
        />
        <select
          onChange={(e) => {
            if (["BTC", "ETH", "XRP"].includes(e.target.value))
              setSelectedAsset(e.target.value);
          }}
          value={selectedAsset}
        >
          <option value="BTC">BTC</option>
          <option value="ETH">ETH</option>
          <option value="XRP">XRP</option>
          {["LTC", "BCH", "LINK", "XLM", "OMG", "ENJ", "BAT", "QNT"].map((asset) => (
            <option
              key={asset}
              onClick={() => window.open("https://coinmetro.exchange")}
              value={asset}
            >
              {asset + "(sign up to access)"}
            </option>
          ))}
        </select>
      </div>
      <div
        style={{
          maxWidth: "960px",
          margin: "5vh auto",
          fontFamily:
            "source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace",
        }}
      >
        <p>
          This institutional grade indicator continually parses data from millions of
          tweets to gauge market sentiment on crypto assets.
        </p>
        <p>
          Sentiment data from social media is used by firms like Fidelity, Sequoia
          and the CME to enhance their trading decision making processes.
        </p>
        <h5 style={{ marginBottom: "5px" }}>Papers:</h5>
        <div style={{ textAlign: "left" }}>
          1.{" "}
          <a
            style={{
              fontSize: "calc(10px + 0.8vmin)",
              display: "inline-block",
              marginBottom: "10px",
            }}
            href="https://www.sciencedirect.com/science/article/abs/pii/S104244312030072X"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i>
              The predictive power of public Twitter sentiment for forecasting
              cryptocurrency prices
            </i>
          </a>
          <br />
          2.{" "}
          <a
            style={{ fontSize: "calc(10px + 0.8vmin)", display: "inline-block" }}
            href="http://essay.utwente.nl/78607/1/Salac_BA_BMS.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i>
              Forecasting of the cryptocurrency market through social media sentiment
              analysis
            </i>
          </a>
        </div>
      </div>
    </div>
  );
}

function getSentimentLabel(value) {
  if (value > 50 && value < 60) {
    return "Slightly Bullish";
  }
  if (value >= 60 && value < 75) {
    return "Bullish";
  }
  if (value >= 75) {
    return "Extremely Bullish";
  }
  if (value === 50) {
    return "Neutral";
  }
  if (value < 50 && value > 40) {
    return "Slightly Bearish";
  }
  if (value <= 40 && value > 25) {
    return "Bearish";
  }
  if (value <= 25) {
    return "Extremely Bearish";
  }
}
